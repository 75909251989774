import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, ExternalLink, ArrowRight } from 'lucide-react';

// Import all images (keeping existing imports)
import leaguetableImage from '../../assets/assets/products/LT page.png';
import ancillaryProducts from '../../assets/assets/products/ancillaryproducts2.png';
import financials from '../../assets/assets/products/CFS DB.png';
import newsLetter from '../../assets/assets/products/Newletter final.png';
import PeVcDirectory from '../../assets/assets/products/directoryPevcInvestors2.png';
import mergerAndAcquisition from '../../assets/assets/products/MA DB.png';
import pere from '../../assets/assets/products/RE DB.png';
import privateEquity from '../../assets/assets/products/PE DB.png';

const FullScreenImageViewer = ({ image, onClose, product }) => (
  <motion.div 
    className="fixed inset-0 z-50 flex items-center justify-center bg-black/60 backdrop-blur-sm"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <motion.div 
      className="relative w-[90%] max-w-4xl max-h-[90vh]"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
    >
      <button 
        onClick={onClose}
        className="absolute top-4 right-4 bg-white/50 hover:bg-white/70 p-2 rounded-full"
      >
        ✕
      </button>
      <img 
        src={image} 
        alt={`${product} full view`} 
        className="w-full h-full object-contain"
      />
    </motion.div>
  </motion.div>
);

const Products = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullScreenImage, setIsFullScreenImage] = useState(false);
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());


  const products = [
    {
      type: "PRIVATE EQUITY",
      product: "Private Equity - Venture Capital Database",
      summary: [
        "Investments since 1998 and exits since 2004 in India-based companies by private equity and venture capital firms.",
        "Includes sub-databases tracking Angel Investing and Incubation/Acceleration activity.",
        "Profiles of PE & VC firms; PE/VC-backed companies and Companies incubated at various Incubators/Accelerators across India.",
        "Shareholding details - Comprehensive & Post Transaction Changes."
      ],
      image: privateEquity
    },
    {
      type: "FINANCIALS",
      product: "Company Financials Search",
      summary: [
        "Private Company Financials data covering 50k+ companies",
        "Includes Profit & Loss, Balance Sheet, Cash Flow statements and Ratios",
        "Company filings (as reported to MCA)",
        "Index of Charges Data",
        "Various Powerful filters to extract the data"
      ],
      image: financials
    },
    {
      type: "MERGER & ACQUISITIONS",
      product: "Merger and Acquisitions Database",
      summary: [
        "Inbound, outbound and domestic deals involving India-based companies.",
        "Enterprise Valuation and multiples",
        "Includes minority stake acquisitions"
      ],
      image: mergerAndAcquisition
    },
    {
      type: "EQUITY",
      product: "Private Equity-Real Estate Database",
      summary: [
        "Investments, Exits and M&A in India-based Real Estate companies and projects.",
        "Comprehensive project details",
        "Trends and analytics in PE-RE sector"
      ],
      image: pere
    },
    {
      type: "NEWSLETTER",
      product: "Deal Digest Newsletter",
      summary: [
        "India's First & Most Exhaustive Transactions Newsletter",
        "Premium Daily Edition",
        {
          text: "Free Weekly Edition",
          link: "https://ventureintelligence.com/weekly-newsletter.htm"
        }
      ],
      image: newsLetter
    },
    {
      type: "DIRECTORY",
      product: "Directory of PE-VC Investors",
      summary: [
        {
          text: "The India Private Equity / Venture Capital Directory",
          link: "https://ventureintelligence.com/pedirectory.htm"
        },
        {
          text: "The India Private Equity-Real Estate Directory",
          link: "https://ventureintelligence.com/peredirectory.htm"
        },
        {
          text: "Directory of Early Stage Investors in India",
          link: "https://ventureintelligence.com/pedirectory.htm"
        },
        {
          text: "Limited Partners Directory",
          link: "https://ventureintelligence.com/lpdirectory.htm"
        },
        "Family Office Directory"
      ],
      image: PeVcDirectory
    },
    {
      type: "Others",
      product: "Other Products & Services",
      summary: [
        {
          text: "Series A Landscape Report",
          link: "https://ventureintelligence.com/seriesareport.htm"
        },
        {
          text: "Private Equity Impact Report",
          link: "https://ventureintelligence.com/peimpact.htm"
        },
        {
          text: "Products for Entrepreneurs",
          link: "https://ventureintelligence.com/entrepreneurs.htm"
        },
        {
          text: "VI Gyan - The Venture Intelligence Blog",
          link: "https://ventureintelligence.blogspot.com/"
        },
        "Customized research and analytics"
      ],
      image: ancillaryProducts
    },
    {
      type: "LEAGUE TABLES",
      product: "League Tables",
      summary: [
        "The Venture Intelligence League Tables, the first such initiative exclusively tracking transactions involving India-based companies.",
        "Based on value of PE and M&A transactions",
        "Compiled based on transactions submitted by advisory firms"
      ],
      image: leaguetableImage
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      if (Date.now() - lastInteractionTime >= 6000) {
        setCurrentIndex((prevIndex) => 
          prevIndex === products.length - 1 ? 0 : prevIndex + 1
        );
      }
    }, 6000); // Changed to 6 seconds

    return () => clearInterval(timer);
  }, [products.length, lastInteractionTime]);

  const handleSlideChange = useCallback((newIndex) => {
    setCurrentIndex(newIndex);
    setLastInteractionTime(Date.now()); 
  }, []);

  const handleNext = () => {
    handleSlideChange(currentIndex === products.length - 1 ? 0 : currentIndex + 1);
  };

  const handlePrev = () => {
    handleSlideChange(currentIndex === 0 ? products.length - 1 : currentIndex - 1);
  };

  const handleDotClick = (index) => {
    handleSlideChange(index);
  };
  

  const renderSummaryItem = (point, index) => {
    if (typeof point === 'string') {
      return (
        <p className="text-gray-600">{point}</p>
      );
    } else if (point.link) {
      return (
        <a 
          href={point.link} 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-gray-600 hover:text-[#bc8b20] underline"
        >
          {point.text}
        </a>
      );
    }
    return null;
  };

  return (
    <section className="bg-slate-100 min-h-[85vh] py-12 flex flex-col" id="products">
      <div className="container mx-auto px-4">
        <div className="text-center ">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Our Products
          </h2>
          <a 
            href="https://www.ventureintelligence.com/trial.htm"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 text-[#bc8b20] hover:text-[#d6b15f] transition-colors"
          >
            <span className="font-medium">Request a Demo</span>
            <ExternalLink className="h-4 w-4" />
          </a>
        </div>

        <div className="relative max-w-7xl mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center h-[500px]"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
              {/* Image Section */}
              <div className="relative group flex justify-center items-center h-[500px]">
                <motion.div
                  className="relative w-[90%] ml-4 md:ml-14 shadow-md flex items-center justify-center"
                  whileHover={{ scale: 1.02 }}
                  transition={{ type: "spring", stiffness: 400, damping: 30 }}
                >
                  <img
                    src={products[currentIndex].image}
                    alt={products[currentIndex].product}
                    className="w-full h-full object-contain"
                  />
                </motion.div>
              </div>

              {/* Content Section */}
              <motion.div
                className="p-8 flex flex-col justify-center h-[500px] overflow-y-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <span className="text-sm font-semibold text-[#bc8b20] uppercase tracking-wider">
                  {products[currentIndex].type}
                </span>
                <h3 className="text-3xl font-bold mt-2 mb-6 text-gray-800">
                  {products[currentIndex].product}
                </h3>
                <ul className="space-y-4">
                  {products[currentIndex].summary.map((point, index) => (
                    <motion.li
                      key={index}
                      className="flex items-start space-x-3"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.3 + index * 0.1 }}
                    >
                      <span className="h-2 w-2 bg-[#bc8b20] rounded-full mt-2" />
                      {renderSummaryItem(point, index)}
                    </motion.li>
                  ))}
                </ul>
                {products[currentIndex].type === "LEAGUE TABLES" && (
                  <a 
                    href="/leagues" 
                    className="inline-flex items-center gap-2 mt-6 text-[#bc8b20]  
                             transition-colors group"
                  >
                    {/* <span>View Details</span> */}
                    <ExternalLink className="h-6 w-6" />
                  </a>
                )}
              </motion.div>
            </motion.div>
          </AnimatePresence>

          {/* Navigation dots */}
          <div className="absolute bottom-0 left-0 right-0 flex justify-center gap-4 mt-8">
            {products.map((_, index) => (
              <button
                key={index}
                onClick={() => handleDotClick(index)}
                className={`w-2 h-2 rounded-full transition-colors duration-300 ${
                  currentIndex === index 
                    ? 'bg-[#bc8b20] scale-125' 
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>

          {/* Navigation arrows */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-12 transform -translate-y-1/2 
                     bg-white p-3 rounded-full shadow hover:bg-gray-50"
          >
            <ChevronLeft className="h-6 w-6 text-gray-600" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-12 transform -translate-y-1/2 
                     bg-white p-3 rounded-full shadow hover:bg-gray-50"
          >
            <ChevronRight className="h-6 w-6 text-gray-600" />
          </button>
        </div>
      </div>

      <AnimatePresence>
        {isFullScreenImage && (
          <FullScreenImageViewer
            image={products[currentIndex].image}
            product={products[currentIndex].product}
            onClose={() => setIsFullScreenImage(false)}
          />
        )}
      </AnimatePresence>
    </section>
  );
};

export default Products;