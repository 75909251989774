import React from "react";
import Container from "../layout/Container";
import CardsContainer from "../cardsContainer/CardsContainer";


const Blog = () => {
  return (
    <div id="whats-new" className=" xl:w-full overflow-x-hidden overflow-y-hidden ">
      <Container>
        <div className="flex-col">
          <h2 className="text-3xl font-bold text-gray-900  text-center">
            What's New
          </h2>
          <div id="cards-carousel" className="flex flex-wrap justify-center mx-2 my-10">
            <CardsContainer />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Blog;
