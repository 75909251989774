import {React , useEffect , useState} from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Navbar from "../navbar/index";
import ContactUs from "../home/ContactUs";

const Layout = ({ children }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const headerOffset = 70; 
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerOffset;
  
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);
  
  
  
  const navLinks = [
    { name: "About Us", to: "/" },
    {
      name: "Products",
      to: "/#products",
      // options: [
      //   { name: "Private Equity - Venture Capital Database", to: "/products/cfs" },
      //   { name: "Company Financials Search", to: "/products/funding" },
      //   { name: "Mergers & Acquisitions", to: "/products/mergers-acquisitions" },
      //   { name: "Private Equity-Real Estate Database", to: "/products/directory" },
      //   { name: "Deal Digest Newsletter", to: "/products/newsletter" },
      //   { name: "Directory of PE-VC Investors", to: "/products/custom-reports" },
      //   { name: "Other Products & Services", to: "/products/custom-reports" },
      // ],
    },
    { name: "League Tables", to: "/leagues" },
    { name: "What's New", to: "/#whats-new" },
    { name: "Our Customers", to: "/#Customers" },
    { name: "Careers", to: "/#careers" },
    { name: "Contact", to: "/#Contact" },
    { name: "Login", to: "https://v2.ventureintelligence.com/login" },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar navLinks={navLinks} />
      <main className="flex-grow">
        {children}
      </main>
      <ContactUs />
    </div>
  );
};

export default Layout;

